/// This function is uncallable in TypeScript, so the compiler will complain if it is called.
/// It can thus be used to make switches and checks exhaustive, by passing the checked value in here in the default case.
export function assertExhausted(unreachable: never) {}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export enum ShouldPayConsultationTypeEnum {
    Doctor = 0,
    Midwife = 1,
    Nurse = 2,
    Dentist = 3,
    Dermatologist = 4,
    Rheumatologist = 5,
    Physiotherapist = 6,
    Psychologist = 7,
    AllergyDoctor = 8,
    SundVaegtDoctor = 9,
    SundVaegtNurse = 10,
    SundVaegtNurseAdHocVideo = 11,
    SundVaegtQuestionnaireMessage = 12,
    PhysiotherapistQuestionnaireMessage = 13,
    Dietitian = 14,
    Veterinarian = 15,
}

export enum ConsultationTypeEnum {
    Message = 0,
    Video = 1,
    Prescription = 2,
    MidwifeMessage = 3,
    MidwifeVideo = 4,
    NurseMessage = 5,
    NurseVideo = 6,
    DentistMessage = 7,
    DentistVideo = 8,
    DermatologistMessage = 9,
    DermatologistVideo = 10,
    RheumatologistVideo = 11,
    PhysiotherapistStartupVideo = 12,
    PsychologistVideo = 13,
    AllergyCheckVideo = 14,
    SundVaegtStartupMessage = 15,
    SundVaegtNurseCheckupVideo = 16,
    SundVaegtDoctorCheckupVideo = 17,
    SundVaegtNurseCheckupMessage = 18,
    SundVaegtNurseAdHocVideo = 19,
    SundVaegtQuestionnaireMessage = 20,
    PhysiotherapistMessage = 21,
    PhysiotherapistCheckupVideo = 22,
    PhysiotherapistQuestionnaireMessage = 23,
    DietitianStartupVideo = 24,
    DietitianCheckupVideo = 25,
    PsychologistCrisisVideo = 26,
    PersonalHealthProgrammeStartup = 27,
    AcneProgrammeStartupMessage = 28,
    AcneProgrammeStartupVideo = 29,
    AcneProgrammeCheckupVideo = 30,
    AcneDermatologistMessage = 31,
    VeterinarianVideo = 32,
}

export enum UserTypeEnum {
    Patient = 0,
    Doctor = 1,
    Backend = 2,
}

export enum PetTypeEnum {
    Dog = 0,
    Cat = 1,
    Rabbit = 2,
    Rodent = 3,
}

export enum PetSexEnum {
    Female,
    FemaleSterilized,
    Male,
    MaleNeutered,
    Unknown,
}

export enum CaregiverRoleEnum {
    Doctor = 0,
    Nurse = 1,
    Dentist = 2,
    Midwife = 3,
    Dermatologist = 4,
    Rheumatologist = 5,
    Physiotherapist = 6,
    Psychologist = 7,
    AllergyDoctor = 8,
    SundVaegtDoctor = 9,
    SundVaegtNurse = 10,
    Dietitian = 11,
    AcneDermatologist = 12,
    Veterinarian = 13,
}

export enum PartnerStatusEnum {
    NoPartner = 0,
    Invited = 1,
    Active = 2,
}

export enum SignupDoctorResponseEnum {
    OK,
    Failed,
    EmailExists,
}

export enum OpenVideoConsultationStatusEnum {
    OK = 0,
    NoDoctorsAvailable = 1,
    TimeSlotOverbooked = 2,
}

export enum ServiceTypeEnum {
    VideoConsultation = 0,
    Message = 1,
    MessageReply = 2,
    Prescription = 3,
    DermatologistMessage = 4,
    DermatologistMessageReply = 5,
    RheumatologistVideo = 6,
    PhysiotherapistStartupVideo = 7,
    DermatologistVideo = 8,
    PsychologistVideo = 9,
    AllergyCheckVideo = 10,
    SundVaegtStartupMessage = 11,
    SundVaegtNurseCheckupVideo = 12,
    SundVaegtDoctorCheckupVideo = 13,
    SundVaegtNurseCheckupMessage = 14,
    SundVaegtNurseCheckupMessageReply = 15,
    SundVaegtNurseAdHocVideo = 16,
    SundVaegtQuestionnaireMessage = 17,
    PhysiotherapistMessage = 18,
    PhysiotherapistMessageReply = 19,
    PhysiotherapistCheckupVideo = 20,
    SundVaegtQuestionnaireMessageReply = 21,
    PhysiotherapistQuestionnaireMessage = 22,
    SundVaegtStartupMessageReply = 23,
    DietitianStartupVideo = 24,
    DietitianCheckupVideo = 25,
    PsychologistCrisisVideo = 26,
    PersonalHealthProgrammeStartup = 27,
    AcneProgrammeStartupMessage = 28,
    AcneProgrammeStartupVideo = 29,
    AcneProgrammeCheckupVideo = 30,
    AcneDermatologistMessage = 31,
    AcneDermatologistMessageReply = 32,
    VeterinarianVideo = 33,
}

export enum OrderStatusEnum {
    ProcessingInEpay = 1,
    ProcessingInLaegecheck = 2,
    Completed = 3,
    Canceled = 4,
}

export enum AvailableRelationTypeEnum {
    Child = 0,
    Partner = 1,
    Himself = 2,
}

export enum DeviceTypeEnum {
    Browser = 0,
    iOS = 1,
    Android = 2,
}

export enum PaymentProvider {
    Company = 0,
    AppStore = 1,
    GoogleWallet = 2,
    ePay = 3,
    DIBS = 4,
    Subscription = 5,
    Coupon = 6,
    ReepayCheckout = 7,
    GracePeriod = 8,
    PriceOverride = 9,
}

export enum InsurancePolicyProviderType {
    None = 0,
    Falck = 1,
    Dss = 2,
    Lego = 3,
    If = 4,
    FalckHealthcare = 5,
    ApPension = 6,
    Pfa = 7,
    Codan = 8,
    CodanIndboforsikring = 9,
    Privatsikring = 10,
    Popermo = 11,
    AlmBrand = 12,
    Langelandsfestival = 13,
    Bdo = 14,
    Kf = 15,
    Jyske = 16,
    Vestjylland = 17,
    Forsia = 18,
    Lb = 19,
    Bauta = 20,
    Runa = 21,
    LbPfa = 22,
    DetFagligeHusForsikring = 23,
    EuroAccident = 24,
    DjoefForsikring = 25,
    PPlus = 26,
}

export enum PhoneCallRejectedReason {
    ConsultationIsAlreadyTaken = 'ConsultationIsAlreadyTaken',
}
